import { isRNWebViewUpdateCredentials } from 'utils/webViewUtils';

export function getCurrentUser() {
  return JSON.parse(localStorage.getItem('currentUser')) || {};
}

export function setOfficeWhenOfficeFiltered(user) {
  if (
    user.company.office_filtered &&
    JSON.parse(localStorage.selectedBuilding || '{}')?.building_id == null
  ) {
    const office = user.offices[0];
    const building = {
      building_id: office?.uid,
      building_name: office?.name,
    };
    return building;
  }

  return null;
}

export function setCurrentUser(user) {
  localStorage.setItem('currentUser', JSON.stringify(user));
  isRNWebViewUpdateCredentials();
  return user;
}

export function setSamlCompanyEmailDomain(companyEmailDomain) {
  localStorage.setItem(
    'samlCompanyEmailDomain',
    JSON.stringify(companyEmailDomain),
  );
  return companyEmailDomain;
}

export function getSamlCompanyEmailDomain() {
  return JSON.parse(localStorage.getItem('samlCompanyEmailDomain')) || '';
}

export function overrideCurrentUserCompany(newCompany) {
  const currentUser = getCurrentUser();

  currentUser.company = newCompany;
  setCurrentUser(currentUser);
}

export function getSubdomain() {
  const currentUser = getCurrentUser();

  return `${
    currentUser?.company?.custom_subdomain_webapp != null
      ? `${currentUser?.company?.custom_subdomain_webapp}.`
      : ''
  }`;
}

export function getQRCodeAllowed() {
  let result = true;
  const currentUser = getCurrentUser();

  const meetingRoomProduct = (currentUser?.company?.products || []).find(
    product => product.product_type === 'meetingroom',
  );

  const qrCodeAllowed =
    meetingRoomProduct?.product_meetingroom?.qr_code_allowed;
  if (typeof qrCodeAllowed !== 'undefined') {
    result = qrCodeAllowed;
  }
  return result;
}

export function getPlan() {
  const currentUser = getCurrentUser();
  const finalProduct = (currentUser?.company?.products || []).find(
    product => product.product_type === 'meetingroom',
  );

  if (currentUser == null || finalProduct == null) return null;
  return finalProduct?.configuration?.plan_status;
}

export function isPremium() {
  return ['trial', 'premium'].includes(getPlan());
}

export function isTrial() {
  return ['trial'].includes(getPlan());
}

export function isSuspended() {
  return ['suspended'].includes(getPlan());
}

// イトーキ(11669)、イトーキ検証環境(12186)
export function isItokiCompanyUser() {
  const currentUser = getCurrentUser();
  return [11669, 12186].includes(currentUser.company.id);
}

// 日産本番環境：11453 | 社内検証環境：11227
export function isNissanEmployee() {
  const currentUser = getCurrentUser();
  return [11453, 11227, 1].includes(currentUser.company.id);
}

export function isReceptionAdmin() {
  const currentUser = getCurrentUser();
  return (
    currentUser?.privilege === 'reception' ||
    currentUser?.privilege_reception === 'admin'
  );
}

export function isNissanReceptionAdmin() {
  return true || (isNissanEmployee() && isReceptionAdmin());
}

export function isAdmin() {
  const currentUser = getCurrentUser();
  return (
    currentUser?.privilege === 'admin' ||
    currentUser?.privilege === 'primary_admin' ||
    currentUser?.admin
  );
}

export function isRedirectToOnetimeAppointment() {
  const currentUser = getCurrentUser();
  return currentUser.company.products.find(
    p => p.product_type === 'meetingroom',
  ).product_meetingroom.redirect_to_onetime_appointment;
}

export function redirectToReceptionist({
  start,
  end,
  title,
  resource_id: resourceId,
}) {
  const rawQuery = {
    start,
    end,
    title,
    resource_uid: resourceId,
  };
  const selectedBuilding = JSON.parse(localStorage.getItem('selectedBuilding'));

  if (selectedBuilding && selectedBuilding.building_id) {
    rawQuery.building_uid = selectedBuilding.building_id;
  }
  const searchQuery = new URLSearchParams(rawQuery);

  window.open(
    `${window.location.protocol}//${
      process.env.REACT_APP_WEB_PATH
    }appointments/new?${searchQuery.toString()}`,
    '_blank',
  );
}

export function hasSignageModeEnabled() {
  const currentUser = getCurrentUser();
  const meetingRoomProduct = currentUser.company.products.find(
    product => product.product_meetingroom !== null,
  );
  return meetingRoomProduct.product_meetingroom.signage_mode_enabled;
}
